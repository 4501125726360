import { useFormik } from "formik";
import React, { useState } from "react";
import logo from "../../img/logo-app.png";
import "./util/index.css";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_FEEDBACK } from "./apollo";
import { messageError, messageSuccess, valiDate } from "../../helper";
import Provinces from "../../components/listProvince";
import UploadIconControl from "./UploadIconControl";
import Branch from "../../components/listBranch";
export default function FeedBackForm(history) {
  const [createFeedBack] = useMutation(CREATE_FEEDBACK);
  const [provinceData, setProvinceData] = useState();
  const [branchData, setBranchData] = useState();

  const {
    handleChange,
    errors,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      reportType: "",
      phone: "",
      content: "",
      image: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors = {};
      if (!values.content) errors.content = "ກະລູນາລາຍງານບັນຫາຂອງທ່ານ";
      if (!values.phone) errors.phone = "ກະລຸນາເບີໂທເພື່ອຮັບການຕອບກັບ";
      if (!values.reportType)
        errors.reportType = "ກະລຸນາເລືອກຫົວເລື່ອງທີ່ຈະຮ້ອງຮຽນ";
      if (!provinceData?.id_state) errors.province = "error";
      if (values.reportType === "UNCONFIRM_COD" && !branchData?.id_branch)
        errors.branch = "error";

      return errors;
    },
    onSubmit: async (values) => {
      console.log("values",values)
      try {
        const { data: updated } = await createFeedBack({
          variables: {
            data: {
              ...(values.reportType === "UNCONFIRM_COD" && {
                branch: parseInt(branchData.id_branch),
              }),
              province: parseInt(provinceData.id_state),
              reportType: String(values.reportType),
              phone: String(values.phone),
              content: String(values.content),
              image: String(values.image),
            },
          },
        });
        if (updated) {
          messageSuccess("ສົ່ງຟອມສຳເລັດແລ້ວ");
          resetForm((values = ""));
        } else {
          messageError("ເພີ່ມຂໍ້ມູນຜິດພາບບໍ່ສຳເລັດ");
        }
      } catch (error) {
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });

  return (
    <div
      className="justify-content-md-center p-1"
      style={{ paddingBottom: 100, marginTop: 10 }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card mt-1">
              <div className="card-header">
                <img src={logo} alt="logo" className="img-fluid" />
              </div>
              <div className="card-body">
                <center>
                  {" "}
                  <h2>ຂໍ້ມູນການຮ້ອງຮຽນບັນຫາການຂົນສົ່ງ========</h2>
                </center>
                <hr />
                <form className="form-horizontal">
                  <div className="form-group">
                    <label className="control-label">
                      ເລືອກແຂວງ{valiDate()}
                    </label>
                    <Provinces
                      size={"lg"}
                      getData={(data) => {
                        setProvinceData(data);
                      }}
                      defaultValue={provinceData?.provinceName}
                      className={errors.province ? "is-invalid" : ""}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label">ເບີໂທ{valiDate()}</label>
                    <div className="input-group">
                      <div class="input-group-prepend" style={{ marginTop: 5 }}>
                        <span class="input-group-text">+856 20</span>
                      </div>
                      <input
                        type="number"
                        className={
                          errors.phone
                            ? "form-control form-control-lg is-invalid"
                            : "form-control form-control-lg"
                        }
                        placeholder="ປ້ອນເບີໂທເພື່ອຮັບການຕອບກັບ "
                        name="phone"
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        value={values.phone}
                        maxLength={8}
                      />
                    </div>
                    <i className="text-danger mt-2">{errors.phone}</i>
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      ເລື່ອງທີ່ຮ້ອງຮຽນ{valiDate()}
                    </label>
                    <select
                      className={
                        errors.reportType
                          ? "form-control form-control-lg is-invalid"
                          : "form-control form-control-lg"
                      }
                      name="reportType"
                      onChange={handleChange}
                      value={values.reportType}
                    >
                      <option value="">ຫົວເລື່ອງ</option>
                      <option value="SYSTEM">ລະບົບ ແລະ ແອັບ</option>
                      <option value="COD">ການຮັບເງິນ COD</option>
                      <option value="UNCONFIRM_COD">
                        ສາຂາບໍ່ກົດຢືນຢັນ COD
                      </option>
                      <option value="REPLY">ການຕອບແຊັດ</option>
                      <option value="SERVICE">ການບໍລິການລູກຄ້າ</option>
                      <option value="LOST">ເຄື່ອງເສຍຫາຍ</option>
                      <option value="BROKED">ເຄື່ອງເປ່ເພ</option>
                      <option value="VEHICLE">ພາຫະນະ</option>
                      <option value="OTHER_TYPE">ບັນຫາອື່ນໆ</option>
                    </select>
                    <i className="text-danger mt-2">{errors.reportType}</i>
                  </div>
                  {values.reportType === "UNCONFIRM_COD" ? (
                    <div className="form-group">
                      <label className="control-label">
                        ເລືອກສາຂາ{valiDate()}
                      </label>
                      <Branch
                        size={"lg"}
                        getData={(data) => {
                          setBranchData(data);
                        }}
                        provinceId={provinceData?.id_state}
                        defaultValue={branchData?.branch_name}
                        className={errors.branch ? "is-invalid" : ""}
                      />
                    </div>
                  ) : null}

                  <div className="form-group">
                    <b className="text-danger">
                      * ຖ້າເປັນບັນຫາກ່ຽວກັບເງິນ COD, ພັດສະດຸເສຍຫາຍ
                      ກະລຸນາປ້ອນເລກບິນຝາກພັດສະດຸ(tracking) ຫຼື ອັບໂຫຼດຮູບ
                    </b>
                    <br />
                    <label className="control-label mt-3">
                      ເນື້ອໃນການຮ້ອງຮຽນ{" "}
                    </label>
                    <textarea
                      className={
                        errors.content
                          ? "form-control form-control-lg is-invalid"
                          : "form-control form-control-lg"
                      }
                      rows="10"
                      placeholder="ເນື້ອໃນການຮ້ອງຮຽນ ຫຼື ປ້ອນເລກພັດສະດຸ(tracking) ເພື່ອສະດວກໃນການແກ້ໄຂບັນຫາ"
                      name="content"
                      onChange={handleChange}
                      value={values.content}
                    ></textarea>
                    <span className="text-danger">{errors.content}</span>
                  </div>
                  <div className="form-group">
                    <label className="control-label">
                      ອັບໂຫຼດຮູບພາບ (ຕົວຢ່າງ: ຮູບບິນຝາກພັດສະດຸທ່ານ)
                    </label>
                    <div
                      style={{ textAlign: "center" }}
                      for="file-upload"
                      className="long"
                    >
                      <UploadIconControl
                        profileImage={values?.image}
                        onSuccess={(res) => {
                          setFieldValue("image", res?.filename, true);
                        }}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    className="btn btn-block rounded btn-lg btn-primary"
                  >
                    <i className="icon-check-circle mr-1" /> ຢືນຢັນສົ່ງ
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
