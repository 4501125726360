//google
export const gcp_icons = "https://storage.googleapis.com/ansx/icons";
export const url_upload_file =
  "https://storage.googleapis.com/anousith-bucket/feedback_images";
export const gcp_thai_laos =
  "https://storage.googleapis.com/ansx/nextday/thai_laos";

// uplaod file
export const gcpUpload = async ({ file, bucketName, pathname, filename }) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bucketName", bucketName);
    formData.append("pathname", pathname);
    formData.append("filename", filename);
    const res = await fetch(
      "https://pro.api.anousith.express/google/upload",
      {
        method: "POST",
        body: formData,
      }
    );
    const resWithBody = await res.json();
    return resWithBody;
  } catch (error) {
    throw new Error(error);
  }
};
