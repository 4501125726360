/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import logo from "../../img/logo-app.png";
import { useLazyQuery } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";
import { QUERY_FEEDBACK } from "./apollo";
import {
  feedbackStatus,
  formatDateTime,
  loadingData,
  reportType,
} from "../../helper";
import { url_upload_file } from "../../helper/s3Client";

export default function FeedBackList({ history }) {
  const { match } = useReactRouter();
  const _phone = match?.params?.phone;
  const [searchValue, setSearchValue] = useState("");
  const [feedback, { data: feedbackData, loading }] = useLazyQuery(
    QUERY_FEEDBACK,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    feedback({
      variables: {
        where: {
          title: searchValue ? searchValue : undefined,
          phone: _phone ? _phone : undefined,
        },
        orderBy: "createdAt_DESC",
        limit: searchValue ? undefined : 1000,
      },
    });
  }, [searchValue]);

  const _onSearch = (e) => {
    let value = e?.target?.value;
    if (!value) value = undefined;
    setSearchValue(value);
  };

  return (
    <div
      className="justify-content-md-center p-1"
      style={{ paddingBottom: 100, marginTop: 10 }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card mt-1">
              <div className="card-header">
                <img src={logo} alt="logo" className="img-fluid" />
              </div>
              <div className="card-body text-center">
                <h2>ຂໍ້ມູນການຮ້ອງຮຽນບັນຫາການຂົນສົ່ງ</h2>
              </div>
            </div>
            <div className="card mt-1">
              <div className="card-header">ຄົ້ນຫາ</div>
              <div className="card-body text-center">
                <input
                  type="search"
                  onChange={(e) => _onSearch(e)}
                  className="form-control form-control-lg"
                  placeholder="ຄົ້ນຫາ..."
                />
              </div>
            </div>
            <div className="card mt-1 pb-5 pt-2">
              <h4 className="ml-2">ລາຍລະອຽດ</h4>
              <div className="section full mt-2">
                <div className="accordion p-0" id="accordionExample2">
                  {loading ? (
                    <center>{loadingData(25)}</center>
                  ) : (
                    feedbackData &&
                    feedbackData?.feedBacks?.data?.map(
                      (item, index) => (
                        console.log(item?.image),
                        (
                          <div className="item">
                            <div className="account-settings">
                              <div className="user-profile">
                                <div
                                  className="form-group"
                                  style={{ padding: 20 }}
                                >
                                  <div
                                    style={{ textAlign: "center" }}
                                    for="file-upload"
                                    className="long"
                                  >
                                    <label
                                      for="file-upload"
                                      style={{ padding: 20 }}
                                    >
                                      {item?.image ? (
                                        <img
                                        className="w-100"
                                          src={
                                            `${url_upload_file}/` +
                                            `${item?.image}`
                                          }
                                        />
                                      ) : null}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-header">
                              <button
                                className="btn collapsed justify-content-between"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapse${index}`}
                              >
                                <span className="mr-3">
                                  {index + 1}&nbsp;&nbsp;{" "}
                                  {item?.reportType
                                    ? reportType(item?.reportType)
                                    : "0"}
                                </span>{" "}
                                <small className="text-secondary">
                                  {item?.status
                                    ? feedbackStatus(item?.status)
                                    : "-"}
                                </small>
                              </button>
                            </div>
                            <div
                              id={`collapse${index}`}
                              className={
                                index === 0
                                  ? "accordion-body collapse show"
                                  : "accordion-body collapse"
                              }
                              data-parent="#accordionExample2"
                            >
                              <div className="accordion-content">
                                {/* timeline */}
                                <div className="timeline">
                                  <div className="item">
                                    <div
                                      className={
                                        item?.status === "REQUESTING" ||
                                        item?.status === "ACCEPTED" ||
                                        item?.status === "PROCESSING" ||
                                        item?.status === "COMPLETED"
                                          ? "dot bg-success"
                                          : "dot bg-secondary"
                                      }
                                    />
                                    <div className="content">
                                      <h4
                                        className={
                                          item?.status === "ACCEPTED" ||
                                          item?.status === "PROCESSING" ||
                                          item?.status === "COMPLETED"
                                            ? "title text-success"
                                            : "title text-secondary"
                                        }
                                      >
                                        ກຳລັງຮ້ອງຮຽນ
                                      </h4>
                                      <div className="text">
                                        ເນື້ອໃນຮ້ອງຮຽນ:{" "}
                                        {item?.content ? item?.content : "-"}
                                      </div>
                                      <small className="text text-warning">
                                        ວັນທີຮ້ອງຮຽນ:{" "}
                                        {item?.createdAt
                                          ? formatDateTime(item?.createdAt)
                                          : "-"}
                                      </small>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="item">
                                    <div
                                      className={
                                        item?.status === "ACCEPTED" ||
                                        item?.status === "PROCESSING" ||
                                        item?.status === "COMPLETED"
                                          ? "dot bg-success"
                                          : "dot bg-secondary"
                                      }
                                    />
                                    <div className="content">
                                      <h4
                                        className={
                                          item?.status === "ACCEPTED" ||
                                          item?.status === "PROCESSING" ||
                                          item?.status === "COMPLETED"
                                            ? "title text-success"
                                            : "title text-secondary"
                                        }
                                      >
                                        ຮັບເລື່ອງແລ້ວ
                                      </h4>
                                      <small className="text">
                                        ຜູ້ຮັບເລື່ອງ:{" "}
                                        {item?.updatedBy
                                          ? item?.updatedBy
                                          : "-"}
                                      </small>
                                      <br />
                                      <small className="secondary">
                                        ວັນທີຮັບເລື່ອງ:{" "}
                                        {item?.updatedAt
                                          ? formatDateTime(item?.updatedAt)
                                          : "-"}
                                      </small>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="item">
                                    <div
                                      className={
                                        item?.status === "PROCESSING" ||
                                        item?.status === "COMPLETED"
                                          ? "dot bg-success"
                                          : "dot bg-secondary"
                                      }
                                    />
                                    <div className="content">
                                      <h4
                                        className={
                                          item?.status === "PROCESSING" ||
                                          item?.status === "COMPLETED"
                                            ? "title text-success"
                                            : "title text-secondary"
                                        }
                                      >
                                        ກຳລັງແກ້ໄຂ
                                      </h4>
                                      <small className="text">
                                        ຜູ້ຮັບແກ້ໄຂ:{" "}
                                        {item?.updatedBy
                                          ? item?.updatedBy
                                          : "-"}
                                      </small>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="item">
                                    <div
                                      className={
                                        item?.status === "COMPLETED"
                                          ? "dot bg-success"
                                          : "dot bg-secondary"
                                      }
                                    />
                                    <div className="content">
                                      <h4
                                        className={
                                          item?.status === "COMPLETED"
                                            ? "title text-success"
                                            : "title text-secondary"
                                        }
                                      >
                                        ແກ້ໄຂສຳເລັດ
                                      </h4>
                                      <div className="text">
                                        <small className="text">
                                          ຜູ້ຮັບແກ້ໄຂ:{" "}
                                          {item?.updatedBy
                                            ? item?.updatedBy
                                            : "-"}
                                        </small>
                                        <br />
                                        <small className="text">
                                          ຂໍ້ຄວາມຕອບກັບ:{" "}
                                          {item?.comment ? item?.comment : "-"}
                                        </small>
                                        <br />
                                        <small className="text">
                                          ວັນທີແກ້ໄຂສຳເລັດ:{" "}
                                          {item?.updatedAt
                                            ? formatDateTime(item?.updatedAt)
                                            : "-"}
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* * timeline */}
                            </div>
                          </div>
                        )
                      )
                    )
                  )}
                </div>
              </div>
            </div>
            <button
              className="btn btn-success btn-lg mt-3 rounded-pill"
              onClick={() => history.push("/")}
              style={{
                zIndex: "9999",
                position: "fixed",
                bottom: 10,
                right: 10,
                borderTopLeftRadius: 30,
              }}
            >
              <i class="icon-edit mr-1" /> ເພີ່ມເລື່ອງ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
