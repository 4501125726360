import { gql } from "apollo-boost";

export const QUERY_FEEDBACK = gql`
  query FeedBacks(
    $where: FeedBackWhereInput
    $orderBy: OrderByInput
    $skip: Int
    $limit: Int
  ) {
    feedBacks(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
      total
      data {
        _id
        title
        content
        reportType
        status
        image
        createdAt
        updatedBy
        updatedAt
        comment
      }
    }
  }
`;

export const CREATE_FEEDBACK = gql`
  mutation CreateFeedBack($data: FeedBackInput!) {
    createFeedBack(data: $data) {
      _id
    }
  }
`;

export const PROVINCES = gql`
  query Provinces(
    $where: ProvinceWhereInput
    $orderBy: OrderByInput
    $skip: Int
    $limit: Int
  ) {
    provinces(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
      total
      data {
        id_state
        provinceName
        provinceCode
        addressInfo
      }
    }
  }
`;
export const BRANCH = gql`
  query Branches(
    $where: BranchWhereInput
    $limit: Int
    $skip: Int
    $orderBy: OrderByInput
  ) {
    branches(where: $where, limit: $limit, skip: $skip, orderBy: $orderBy) {
      total
      data {
        id_branch
        branch_name
      }
    }
  }
`;
