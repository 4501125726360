import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import FeedBackList from "../pages/feedback";
import FeedBackForm from "../pages/feedback/form";
import ListModel from "../pages/feedback/ListMode";
import FeedBack from "../pages/feedback/Modal";
export default function Routes() {
  return (
    <Router>
          <html style={{backgroundColor:'#ccc',margin:-20}}>
        <Switch>
        <Route
            exact
            path="/"
            component={(props) => <FeedBack {...props} />}
          />
          <Route
            exact
            path="/FormFeedBack/"
            component={(props)=> <FeedBackForm {...props} />}
          />
          <Route
            exact
            path="/"
            component={ <ListModel />}
          />
          <Route
            exact
            path="/xwEum8a0x2dws5a0ws/:phone"
            component={(props) => <FeedBackList {...props} />}
          />
        </Switch>
     </html>
    </Router>
  );
}
