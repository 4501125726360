import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import { loadingData } from "../helper";
import { BRANCH } from "../pages/feedback/apollo";

function ListBranch({ size, style, getData, defaultValue, className , provinceId }) {
  const inputSize =
    size === "lg"
      ? "from-control-lg"
      : size === "sm"
      ? "from-control-sm"
      : "from-control-md";
  const [item, setItem] = useState();
  const [searchValue, setSearchValue] = useState();
  const [FetchData, { data, loading }] = useLazyQuery(BRANCH);

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          branch_name: searchValue ? searchValue : undefined,
          provinceID: provinceId,
        },
      },
    });
  }, [FetchData,provinceId, searchValue]);


  const handleClick = (value) => {
    if (!value && getData) {
      getData(null);
      setItem(false);
      return;
    }
    try {
      const result = data?.branches?.data.filter(
        (obj) => obj?.branch_name === value
      );
      if (result.length > 0 && getData) {
        getData(result[0]);
        setItem(false);
      }
    } catch (error) {
      if (getData) getData();
    }
  };
  return (
    <>
      <input
        type={"button"}
        className={`form-control text-left ${inputSize} ${className}`}
        style={{ textAlign: "left" }}
        value={defaultValue ? defaultValue : "ເລືອກສາຂາ..."}
        onClick={() => setItem(true)}
      />
      <Modal
        show={item}
        centered
        style={style}
        className={className}
        onHide={() => setItem(false)}
      >
        <Modal.Header>
          <Modal.Title className="fs-5">ເລືອກສາຂາ</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <div className="form-group mb-2">
            <input
              type={"search"}
              className="form-control form-control-lg"
              onChange={(e) => setSearchValue(e.target.value)}
              onInput={(e) => {
                if (!e.target.value && getData) getData();
              }}
              placeholder="ຄົ້ນຫາ"
            />
          </div>
          {loading ? (
            <center>{loadingData(25, "ກຳລັງໂຫຼດຂໍ້ມູນ...")}</center>
          ) : (
            <div className="mt-1 ">
              <div
                className="py-2 border-bottom"
                onClick={() => handleClick("")}
              >
                <span>
                  <i className="fa fa-map-marker-alt text-danger me-1" />{" "}
                  ເລືອກຄ່າເລີ່ມຕົ້ນ
                </span>
              </div>

              {data?.branches?.data?.map((data, index) => (
                <div
                  className="d-flex py-2 border-bottom"
                  key={index}
                  onClick={() => handleClick(data?.branch_name)}
                >
                  <span className="text-black">
                    {" "}
                    <i className="fa fa-map-marker-alt text-danger mr-2" />{" "}
                    {data?.branch_name}
                  </span>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button size="lg" variant="primary" onClick={() => setItem(false)}>
            <i className="icon-close " />
            ຍົກເລີກ
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ListBranch;
